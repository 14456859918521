.reaction-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 25px;
    margin-top: 5px;
    margin-bottom: -5px;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 0px 0px 4px 4px;
    padding: 0;
}

/* Live/Settled text container */
.status-text {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 600;
    gap: 5px;
    color: black !important;
    /* Ensures text remains black */
}

/* Icon styling - Same size as text */
.status-icon {
    font-size: 1rem;
    /* Match text size */
    vertical-align: middle;
}

/* Only color the icon */
.status-icon.Live {
    color: rgb(230, 0, 0);

    /* Red for Live */
}

.status-icon.settled {
    color: rgb(10, 169, 10);
    /* Green for Settled */
}

/* Reaction totals */
.reaction-totals {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.1rem;
    font-weight: 600;
}

/* Default unreacted state */
.reaction-totals.unreacted .reaction-icon,
.reaction-totals.unreacted .total-play {
    color: #555;
    transition: color 0.3s ease-in-out;
}

/* Reacted state */
.reaction-totals.reacted .reaction-icon,
.reaction-totals.reacted .total-play {
    color: #5865F2;
    transition: color 0.3s ease-in-out;
}

/* Ensures all icons and text are aligned */
.reaction-icon {
    font-size: 1rem;
    vertical-align: middle;
}

.total-play {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: -1px;
}