.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: #333;
    text-align: left;
}

.error-title {
    font-size: 3rem;
    font-weight: bold;
    border-bottom: 5px solid rgb(200, 0, 0);
    padding: 10px;
    margin-top: 50px;
}

.error-actions {
    display: flex;
    gap: 1rem;
}

.error-button {
    background-color: #f1f1f1;
    color: black;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
}