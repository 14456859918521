/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 10% opacity */
  backdrop-filter: blur(5px);
  z-index: 2000;
}

/* Modal Box (entire popup) */
.modal-box {
  background-color: #ffffff; /* Solid white background for the full modal */
  border-radius: 4px; /* Reduced border radius */
  padding: 30px;
  width: 400px;
  z-index: 3000; /* Bring modal content to the front */
  text-align: center;
  position: relative; /* For positioning close icon */
}

.google-login-button{
  font-weight: 600;
}

/* Modal Logo */
.modal-logo {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 50px; /* Adjust size of the logo */
  height: auto;
  z-index: 4000;
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  z-index: 4000;
}

/* Modal Content */
.modal-content {
  margin-top: 30px; /* Ensure content starts below the logo */
  margin-bottom: 20px; /* Space below modal content */
}

/* Modal Header */
.modal-content h2 {
  margin-bottom: 25px;
  font-size: 2em;
  text-align: center;
}

/* Separator for 'or' */
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  font-size: 1rem;
  color: #888;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #ddd;
  margin: 0 10px;
}

/* Other styles remain the same */


/* Form Styles */
.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.form-submit-button {
  width: 100%;
        border: 0px solid white;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1.2rem;
    font-weight: 600;
    background: linear-gradient(135deg,
          #6200ff,
          #5865F2,
          #0040ff,
          #1500ff,
          #0040ff,
          #5865F2,
          #6200ff);
      background-size: 400% 400%;
      animation: gradientAnimation 15s ease-in-out infinite;
}

.form-message {
  margin-top: 10px;
  font-size: 0.9rem;
  color: red;
}

/* Toggle between Login/Register */
.toggle-form-button {
  margin-top: 20px;
  padding: 10px;
  font-size: 1rem;
  color: #555;
  background: none;
  border: none;
  cursor: pointer;
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  z-index: 4000;
}

/* Error Message */
.error-message {
  color: rgb(236, 0, 0);
  font-size: 0.9rem;
  margin-top: 5px;
  text-align: center;
  width: 100%;
}
.password-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.password-input-container input {
  flex: 1;
  padding-right: 40px; /* Ensure space for the toggle button */
}

.toggle-password-visibility {
  position: absolute;
  right: 10px; /* Adjust as needed */
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1.2rem; /* Adjust size to match input */
  color: #555; /* Neutral color for the icon */
  transition: color 0.3s ease;
}