.discord-message {
    font-size: 1.2em;
    font-weight: 600;
    display: flex;
    /* Enable Flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Optional: Center horizontally */
    height: 100%;
    /* Ensure it fills the container height */
    text-align: center;
    /* Center the text within the message */
    color: black;
}

.discord-card {
    border-radius: 4px;
    padding: 0;
    /* Remove padding to allow banner to extend to edges */
    background-color: white;
    margin-bottom: 10px;
    overflow: hidden;
    /* Ensure child elements like the banner don’t overflow the card */
    transition: transform 0.2s ease, box-shadow 0.2s ease, border 0.2s ease;
}

.discord-card:hover {
    transform: scale(1.02);
    /* Slightly increase the size */
}

.black-discord-icon{
    font-size: 1.2rem;
    margin-right: 8px;
    padding-top: 2px;
}

.locked-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5865f286;
    color: white;
    border: 0px solid #6f7bfc;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin: 15px;
    margin-top: 5px;
}

.lock-icon {
    margin-right: 8px;
    font-size: 1rem;
}

.locked-button.join-now {
    background-color: #5865f2;
    color: #fff;
    cursor: pointer;
}