/* General Styles */
.feed-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  
}

.feed-loading,
.feed-error {
  text-align: center;
  font-size: 1.2em;
  color: #4a4a4a;
  margin-top: 50px;
    margin-bottom: 50px;
}

.feed-empty {
  text-align: center;
  font-size: 1.5rem;
  color: #555;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 20px;
  background-size: cover;
  /* Ensure the image covers the entire container */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Prevent image repetition */
  border-radius: 4px;
  margin-bottom: 2px;
  margin-top: 5px;
  background: #f1f1f1;
}

.bed-icon{
  color: #555;
}

.feed-empty:hover {
  transform: scale(1.01);
  /* Slight zoom effect on hover */
}

.feed-post-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 20px 0;
}

.divider-line {
  flex-grow: 1;
  border: none;
  border-top: 2px solid #adadad;
}

.divider-text {
  font-size: 1.2em;
  color: #adadad;
  background-color: #fcfcfc;
  padding: 0 10px;
  margin-top: -5px;
}