/* Shared banner styles */
.banner {
  width: 100%;
  position: relative;
  border-radius: 5px;
  z-index: 1;
}

.banner-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  display: block;
  border-radius: 5px, 5px, 0px, 0px;
}

/* Profile picture styles */
.profile-picture-container {
  position: relative;
  width: 125px;
  height: 125px;
  margin-top: -60px;
  margin-left: 15px;
  border: 7px solid white;
  border-radius: 50%;
  z-index: 2;
}

.profile-picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* Editable overlay styles */
.editable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.profile-picture-overlay{
  border-radius: 50%;
}

.banner-overlay {
  border-radius: 5px, 5px, 0px, 0px;
}

.is-editing .editable-overlay {
  opacity: 1;
}

/* Text styles */
.username {
  font-size: 1.1rem;
  color: #555;
    font-weight: 600;
  margin-left: 15px;
  margin-top: 10px;
}

.display-name {
  font-size: 2rem;
  font-weight: 600;
  margin-left: 15px;
  margin-top: -10px;
}

.display-name-edit {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 15px;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  width: 92%;
}

.bio {
  margin-right: 15px;
  font-size: 1.1rem;
  margin-left: 15px;
  color: #555;
  line-height: 1.5;
  margin-top: -15px;
}

.bio-edit {
  padding: 5px;
  font-size: 1.1rem;
  /* Match the font size */
  /* Match the margin */
  color: #555;
  /* Match the color */
  line-height: 1.5;
  /* Match the line height */
  width: calc(100% - '15px');
  /* Account for margin on both sides */
  border: 2px solid #f1f1f1;
  /* Add a border for edit mode */
  border-radius: 4px;
  /* Add padding for better appearance */
  box-sizing: border-box;
  /* Include padding and border in width */
  background-color: #fff;
  /* Optional: White background */
  resize: none;
  /* Disable resizing */
  overflow-y: auto;
  /* Allow scrolling if text overflows */
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.edit-icon-large {
  position: absolute;
  top: 115px;
  right: 0px;
  cursor: pointer;
  font-size: 1rem;
  padding: 12px;
  border-radius: 50%;
  color: white;
  margin-right: 15px;
    background: #555;
}

/* Parent container for the selected leagues in saved mode */
.selected-leagues {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  /* Add 10px padding */
  gap: 5px;
  /* Space between buttons */
}

.char-count {
  font-size: 0.9em;
  /* Adjust the size as needed */
  color: #555;
  /* Light gray color for contrast */
  text-align: right;
  /* Align to the right edge of the container */
  display: block;
  /* Ensures it spans the full width */
  /* Add some spacing above */
  margin-right: 25px;
  /* Match container padding */
  padding: 5px;
  margin-top: -60px;
}

.edit-icon {
  color: white;
  font-size: 1.5rem;
}

.status-row {
  display: flex;
  justify-content: center; /* Center the text */
  align-items: center;
}

.followers-following {
  font-size: 1.2rem;
    padding-left: 15px;
    font-weight: 600;
    Color: black;
    margin-top: 5px;
    margin-bottom: 5px;
}

.subscribers {
    font-size: 1.2rem;
  margin-top: 5px;
      margin-bottom: 5px;
  padding-left: 15px;
  font-weight: 600;
  color: #5865F2;
}

/* Default league button */
.league-button {
    font-size: 1rem;
      background-color: #f1f1f1;
      padding: 6px 8px;
      border-radius: 4px;
      white-space: nowrap;
      font-weight: 600;
      color: black;
      border: none;
}

.show-more-btn {
  background: none;
  border: none;
  padding: 0;
  color: #5865F2; /* Customize the color */
  text-decoration: none; /* Removes the underline */
  cursor: pointer;
  font-size: inherit; /* Matches the text size of surrounding content */
}
