/* Kappers container */
.kappers-container {
  margin: 20px auto;
  padding: 10px;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -20px;
  margin-bottom: 10px;

}

.search-bar-container:hover {
  transform: scale(1.01);
  /* Slightly increase the size */

}

.search-bar-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  padding: 6px;
  padding-left: 10px;
  background-color: #fff;
  
}

.search-icon {
  font-size: 1.2rem;
  color: black;
  margin-right: 8px;
}

.search-bar {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  background: transparent;
}

/* Kapper grid layout */
.kappers-grid {
  display: flex;
  flex-direction: column;
  margin-top: -5px;
}

.kapper-card {
  position: relative;
  /* Allow pseudo-element positioning */
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* Change to column layout */
  cursor: pointer;
  margin-bottom: 10px;
  overflow: hidden;
  /* Prevent the pseudo-element from overflowing the card */
  border: solid 2px #f1f1f1;
}

.kapper-card::before {
  content: '';
  /* Required for pseudo-element */
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 5px;
  width: 4px;
  /* Adjust the width of the gradient border */
  background:  #f1f1f1;
  /* Blue to purple gradient */
  border-radius: 2px;
}

.kapper-card.pro-card::before {
  background: #5865F2;
  /* Blue to purple gradient */
}

.kapper-card:hover {
  transform: scale(1.01);
}

/* Profile picture and username container */
.kapper-info {
  display: flex; /* Align profile picture and username on the same row */
  align-items: center; /* Vertically center the profile picture with the username */
  gap: 10px; /* Space between profile picture and username */
  margin-bottom: 5px; /* Space between username and leagues */
}

/* Profile picture */
.kapper-profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

/* Kapper username */
.kapper-display-name {
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 0px; /* Remove the default margin */
}

/* Kapper leagues */
.kapper-leagues {
  display: flex; /* Use flexbox to display the leagues in one row */
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 5px; /* Add space between league items */
  justify-content: flex-start; /* Align leagues to the left */
}

.kapper-league {
    font-size: .9rem;
      background-color: #f1f1f1;
      padding: 6px 8px;
      border-radius: 4px;
      white-space: nowrap;
          font-weight: 600;
}


.no-leagues {
  color: #999;
  font-style: italic;
}

.league-filters {
  display: flex;
  gap: 5px;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent buttons from wrapping */
  scrollbar-width: none;
  /* For Firefox */
  margin-bottom: 7px;
  margin-top: -4px
}

.league-filter-button {
  flex-shrink: 0;
  border: 0px;
  background-color: transparent;
  color: #555;
  font-size: .9rem;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  margin: 1px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
      font-weight: 600;
}

.league-filter-button.active {
  background-color: #f1f1f1;
  color: black;
}

.league-filters::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Edge */
}

.badge-icon-small {
  font-size: .9rem;
  color: white;
  /* Add spacing between the username and the icon */
  position: relative;
  /* To enable top adjustment */
  top: -5px;
  /* Move the icon up by 10px */
}