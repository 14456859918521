.dismiss-alert-button {
    background: transparent;
    border: none;
    font-size: 1.5em;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    color: #555;
    right: 0;
    top: 0;
    margin-right: 5px;
}

.feed-alert-image {
    width: 100%;
    margin-top: -15px;
    object-fit: cover;
    border-radius: 4px;
}

.feed-post-title {
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
}

.alert-body{
    font-size: 1rem;
    font-weight: 600;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.feed-alert-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    padding-top: 10px;
}