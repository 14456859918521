
/* Private Discord Badge (Top Right) */
.discord-badge {
  position: absolute;
  top: 20px;
  /* Increased to prevent overlap */
  right: 20px;
  /* Slightly adjusted for better spacing */
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  font-weight: 700;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  background: linear-gradient(135deg, #f7d04c, #ca8d01);
  z-index: 10;
  /* Ensures it appears on top */
}

/* Discord Icon */
.discord-badge-icon {
  font-size: 1rem;
  color: white;
}

    /* Center the subscribe button */
    .subscribe-button-container {
      display: flex;
      justify-content: center;
      /* Center horizontally */
      /* Add some space above the button */
    }
  
    .subscribe-button {
      width: 100%;
      padding: 10px;
      font-size: 1.2rem;
      font-weight: bold;
      border-radius: 4px;
      cursor: pointer;
      border: 0px solid #5865F2;
      color: white;
      /* Text color */
  
  
      background: linear-gradient(135deg,
          #6200ff,
          #5865F2,
          #0040ff,
          #1500ff,
          #0040ff,
          #5865F2,
          #6200ff);
      background-size: 400% 400%;
      animation: gradientAnimation 15s ease-in-out infinite;
    }
  
    .subscribe-button.subscribed {
      background: linear-gradient(135deg,
          #6200ff,
          #5865F2,
          #0040ff,
          #1500ff,
          #0040ff,
          #5865F2,
          #6200ff);
      background-size: 400% 400%;
      animation: gradientAnimation 15s ease-in-out infinite;
      color: white;
    }
  
    .follow-button-container {
      display: flex;
      justify-content: center;
      /* Center horizontally */
      /* Add some space above the button */
    }
  
    .follow-button {
      width: 100%;
      padding: 10px;
      font-size: 1.2rem;
      font-weight: normal;
      border-radius: 4px;
      cursor: pointer;
      background-color: #f1f1f1;
      border: 0px solid white;
      color: black;
      /* Text color */
      transition: background-color 0.3s;
    }
  
    .follow-button.followed {
      background-color: #ddd;
    }

        /* Daily Post Average Text */
        .daily-post-average {
          font-size: 1.2rem;
          font-weight: 600;
          color: #5865F2;
          /* Dark gray for readability */
          text-align: left;
        }