.discord-server-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* Allow children to stretch the full width */
  padding: 15px;
}

.discord-server-header {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
}

.discord-icon {
  margin-right: 3px;
  color: white;
}

.discord-server-status {
  color: #555;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align left for status text */
}

.discord-button {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 100%;
  /* Ensure the button takes full width */
  background-color: #6f7bfc;
  color: white;
}

.discord-server-actions {
  width: 100%;
  /* Ensure the button's parent stretches the full width */
}
