/* General Container Styling */
.create-alert-container {
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  overflow: hidden;
}

/* Buttons */
.create-alert-open-button {
  position: relative;
    width: 100%;
    background-color: white;
    font-size: 1.5rem;
    border: none;
border-bottom: 4px solid #5865F2;
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    transition: background-color 0.3s, color 0.3s;
}

.create-alert-button-text {
  font-size: 2rem;
  font-weight: 600;
}

.create-alert-submit-button {
  width: 100%;
        border: 0px solid white;
        font-weight: 600;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1.2rem;
    margin-top: 20px;

    background: linear-gradient(135deg,
          #6200ff,
          #5865F2,
          #0040ff,
          #1500ff,
          #0040ff,
          #5865F2,
          #6200ff);
      background-size: 400% 400%;
      animation: gradientAnimation 15s ease-in-out infinite;
}

/* Disclaimer */
.disclaimer {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: grey;
}

/* Tab Menu */
.create-alert-tab-menu {
  display: flex;
  justify-content: left;
  gap: 20px;
  margin-top: 5px;
}

.create-alert-tab {
  cursor: pointer;
  border-bottom: 3px solid transparent;
  border-radius: 2px;
  transition: color 0.3s, border-bottom 0.3s;
  font-size: 1.2rem;
  padding: 8px 12px;
  color: #555;
}

.create-alert-tab.active {
  color: black;
  font-weight: 600;
}

/* Input Fields */
.create-alert-container #title {
  font-size: 1.5rem;
  color: black;
  font-weight: normal;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
  border: 2px solid #f1f1f1;
  margin-top: 10px;

}

.create-alert-container #body {
  font-size: 1rem;
  color: black;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  border-radius: 4px;
  width: 100%;
  min-height: 100px;
  border: 2px solid #f1f1f1;

}
