
.stats-header {
    margin-bottom: 5px;
        font-size: 1.2rem;
        text-align: left;
        color: black;
}

.stats-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
}

.stat-label {
    font-weight: 600;
}

.stat-value {
    font-weight: 400;
}

.toggle-buttons {
    display: flex;
    gap: 5px;
}

.toggle-btn {
    background: #f1f1f1;
    border: 0px solid #ccc;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

.toggle-btn.active {
    color: white;
    border-color: #5865F2;
}

.loading-text {
    text-align: center;
    font-size: 14px;
    color: gray;
}

.error-text {
    text-align: center;
    color: red;
    font-weight: bold;
}

.no-data-text {
    text-align: center;
    color: gray;
}