/* General Container Styling */
.create-post-container {
  padding: 20px;
  background-color: white;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.create-post-content{
  font-size: 1rem;
  color: black;
  font-weight: 600;
}

.odds-textbox-group {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.odds-textbox {
  padding: 5px;
  font-size: 1.5rem;
  color: black;
  font-weight: 600;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  width: 100px;
  text-align: left;
  box-sizing: border-box;
  margin: 10px;
  margin-right: 0px;
}

/* Slider Styling */
.slider-container {
  margin-top: 20px;
  margin-bottom: 30px;
}

.slider-header {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
  height: 6px;
  background: #ddd;
  border-radius: 5px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -10px;
  box-shadow: 0 0 0 3px black;
  border: 3px solid var(--thumb-color, black);
}

.slider::-moz-range-thumb,
.slider::-ms-thumb {
  width: 25px;
  height: 25px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -10px;
  box-shadow: 0 0 0 3px black;
  border: 3px solid var(--thumb-color, black);
}

/* League Buttons */
.create-post-league-container {
  margin-top: -9px;
  margin-bottom: 7px;
}

.create-post-league-row {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 6px;
  gap: 6px;
}

.create-post-league-row::-webkit-scrollbar {
  display: none;
}

.create-post-league-button {
  flex-shrink: 0;
  border: 0;
  background-color: transparent;
  color: #555;
  border: 2px solid #f1f1f1;
  border-radius: 6px;
  margin: 1px;
  padding: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, border-color 0.3s;
}

.create-post-league-button.selected {
  background: #f1f1f1;
  border: 2px solid #f1f1f1;
  color: black;
}

/* Image Upload */
.upload-image-container {
  margin-top: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
  text-align: center;
  background-color: #f9f9f9;
}

.upload-label {
  cursor: pointer;
}

.upload-icon {
  font-size: 1.5rem;
  color: #555;
  margin-top: 40px;
}

.upload-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  margin-top: 10px;
    margin-bottom: 40px;
}

.upload-input {
  display: none;
}

.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.clear-image-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 15px;
  border: none;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Alert Messages */
.alert {
  margin-top: 10px;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
}

.alert-danger {
  color: rgb(238, 0, 0);
  text-align: center;
  margin-top: 5px;
}

.alert-info {
  color: rgba(43, 177, 43);
  text-align: center;
  margin-top: 5px;
}

/* Buttons */
.create-post-modal-button {
  width: 100%;
  border: 0px solid white;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 600;
  transition: background-color 0.3s;

  background: linear-gradient(135deg,
        #6200ff,
        #5865F2,
        #0040ff,
        #1500ff,
        #0040ff,
        #5865F2,
        #6200ff);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease-in-out infinite;
}

.create-post-open-button {
  position: relative;
  width: 100%;
border-bottom: 4px solid #5865F2;
  color: black;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  transition: background-color 0.3s, color 0.3s;
}

.create-post-button-text{
font-size: 2rem;
font-weight: 600;
}

.create-post-button-icon {
  font-size: 2rem;
  color: #555;
}

.character-counter {
  font-size: 0.85rem;
  color: #555;
  text-align: right;
  margin-top: 5px;
}

.note-toggle {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
  cursor: pointer;
}

.note-toggle-hide {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
  cursor: pointer;
  margin-top: -20px;
}

.analysis-textarea {
  padding: 5px;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.5;
  width: calc(100% );
  /* Account for margin on both sides */
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  box-sizing: border-box;
  /* Include padding and border in width */
  background-color: #fff;
  resize: none;
  /* Disable resizing */
  overflow-y: auto;
  /* Allow scrolling if text overflows */
  margin-top: 5px;
}