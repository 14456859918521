@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

/* Gradient Header Section */
.landing-header-section {
  position: relative;
  text-align: left;
  color: white;
  padding: 80px 20px;
    margin-top: -65px;

  /* Animated Gradient */
  background: linear-gradient(135deg,
      #6200ff,
      #5865F2,
      #0040ff,
      #1500ff,
      #0040ff,
      #5865F2,
      #6200ff);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease-in-out infinite;
}

/* Angled White Separator */
.landing-header-section::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 100px;
  background: #fcfcfc;
  transform: skewY(-2deg);
}

/* Text Styling */
.landing-header {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.landing-subheader {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 auto;
}

/* White Content Section Below */
.landing-content-section {
  background: #fcfcfc;
  text-align: left;
  padding: 100px 20px;
  color: #333;
}

/* Buttons */
.landing-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.landing-button {
  background: #0066ff;
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.landing-button:hover {
  background: #004ccc;
}