.card-content {
  margin: 5px;
}

.average-odds-value {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: 600;
  background-color: #f1f1f1;
  padding: 8px 12px;
  border-radius: 3px;
  white-space: nowrap; /* Prevents wrapping */
  min-width: 100px; /* Ensures a minimum box size */
  text-align: center; /* Aligns text in the center */
}


.average-odds-scale {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 30px;
}

.scale-min {
  color: #555;
  font-size: 0.9rem;
  margin-right: 10px;
}

.scale-max {
  color: #555;
  font-size: 0.9rem;
  margin-left: 10px;
}

.scale-line {
  flex-grow: 1;
  position: relative;
  height: 12px;
  border-radius: 3px;
}

.ticker {
  position: absolute;
  top: 0px;
  width: 2px;
  height: 24px;
  background-color: black;
  transform: translateX(-50%);
}

.risk-level-text {
  margin-bottom: 5px;
  font-size: 1.2rem;
  text-align: left;
  color: black;
  font-weight: 600;
  margin-left: 10px;
}