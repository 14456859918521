
  .kapper-details .card-text {
    margin-bottom: 10px;
  }
  
  .kapper-details a {
    color: #1da0f2;
    text-decoration: none;
  }
  
  .kapper-container {
    margin-top: 20px; /* Add space between the navbar and the card */
  }

.blank-space {
  height: 15px; /* Match the height of the subscribe button */
}

.leagues-container {
  margin-left: 15px; /* Add a 10px margin to the left of the entire group */
  display: flex; /* Optional: Keeps the league buttons in a row */
  flex-wrap: wrap; /* Optional: Allows wrapping if the buttons don't fit in one row */
  gap: 5px; /* Optional: Adds space between individual league buttons */
}

.badge-icon{
  font-size: 1rem;
  color: white;
    /* Add spacing between the username and the icon */
    position: relative;
    /* To enable top adjustment */
    top: -15px;
    /* Move the icon up by 10px */
}