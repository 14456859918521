.alerts-empty{
    text-align: center;
        font-size: 1.5rem;
        color: #555;
        font-weight: 600;
        padding-top: 20px;
        padding-bottom: 10px;
        /* Replace with the path to your image */
        background-size: cover;
        /* Ensure the image covers the entire container */
        background-repeat: no-repeat;
        /* Prevent image repetition */
        border-radius: 4px;
background: #f1f1f1;
}