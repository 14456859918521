
.average-odds-value {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: .9rem;
background-color: #f1f1f1;
    padding: 4px 6px;
    /* Adjusted padding for dynamic text */
    border-radius: 3px;
    white-space: nowrap;
    /* Prevents wrapping */
    min-width: 100px;
    /* Ensures a minimum box size */
    text-align: center;
    /* Aligns text in the center */
}


.average-odds-scale {
    margin-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 30px;
}

.scale-line {
    flex-grow: 1;
    position: relative;
    height: 6px;
    border-radius: 2px;
}

.ticker {
    position: absolute;
    top: 0px;
    width: 2px;
    height: 24px;
    background-color: #555;
    transform: translateX(-50%);
}

.risk-level-text {
    margin-bottom: 5px;
    font-size: 1.2rem;
    text-align: left;
    margin-left: 10px;
    color: black;
}