.unsubscribe-button {
  position: absolute;
  /* Keep the button positioned relative to the card */
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: white;
  color: rgb(236, 0, 0);
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
