/* General App styles */
html, body, #root {
  height: 100%; /* Ensure the root container takes up full height */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the main container takes full height of the viewport */
  background-color: #fcfcfc;
  align-items: center;
  /* Ensures that the content is aligned horizontally when width is smaller */
}

.main-content {
  flex: 1;
  /* Allow main content to take up the available space */
  padding-bottom: 400px;
  /* Add extra padding at the bottom for scrolling */
  /* Limit the width */
  width: 100%;
  /* Make it responsive */
  margin: 0 auto;
  /* Center the content horizontally */

}

/* Shared card styles */
.card {
  border-radius: 4px;
  padding: 0; /* Remove padding to allow banner to extend to edges */
  background-color: #f8f8f8;
  margin-bottom: 10px;
  overflow: hidden; /* Ensure child elements like the banner don’t overflow the card */
  transition: transform 0.2s ease;
  border: 2px solid #f1f1f1 !important;
}

.card:hover {
  transform: scale(1.01);
  /* Slightly increase the size */
}

.card-margin {
padding: 10px;
}

/* Shared container styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

/* Footer styling */
.footer {
  background-color: #fcfcfc;
  color:#555;
  text-align: center;
  padding: 20px;
  margin-top: auto;
}

/* Divider styling */
.universal-divider-line {
  border: none;
  border-bottom: 2px solid #adadad; /* Thin gray line */
  margin-top: 0px;
}

.universal-divider-line-margin {
  border: none;
  border-bottom: 2px solid #adadad; /* Thin gray line */
  margin: 10px;
  margin-top: 0px;
}

.button-group {
  position: absolute;
    top: 105px;
    right: 0px;
    cursor: pointer;

    padding: 15px;
    color: black;
}

.universal-save-button,
.universal-cancel-button {
        font-size: 1rem !important;
          font-weight: 600;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    padding: 8px 10px !important;
}

.universal-save-button {
      background: #5865F2;
  color: white;

}

.universal-cancel-button {
  color: black;
  background-color: white;
}