/* Add larger timestamp styling */
.feed-post-timestamp-large {
    font-size: 1.2rem;
    text-align: left;
    color: black;
    font-weight: 600;
    margin-top: -5px;
}

.feed-post-card {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.feed-post-card.disappearing {
    opacity: 0;
    transform: translateY(-30px);
    /* Slide up slightly */
}

.kapper-post-odds {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
        margin-top: -5px;
}