/* Default Navbar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: black;
  position: sticky !important;
  top: 0;
  z-index: 1000;
  width: 100%;
  transition: all 0.3s ease;
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

/* Adjust Logo Size */
.navbar-logo-img {
  height: 40px;
  transition: height 0.3s ease;
}

.navbar-scrolled .navbar-logo-img {
  height: 0px !important;
}

/* Transparent Navbar when Logged Out on Landing Page */
.transparent-navbar {
  background: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: none;
  padding: 20px 15px;
}

/* Navbar left section (logo and brand) */
.navbar-left {
  display: flex;
  align-items: center; /* Align logo vertically */
  margin-left: 10px; /* Space between logo and username */
}
/* Adjust spacing for unscrolled state */
.nav-links {
  display: flex;
  align-items: right;
  list-style: none;
  gap: 25px; /* Tight spacing when at the top */
  margin: 0;
  margin-right: 15px;
  padding: 0;
  transition: gap 0.2s ease;
}

.nav-links a {
  text-decoration: none;
  color: #555;
}

/* Non-selected icons in sidebar remain grey */
.sidebar-menu {
    padding: 0;
      /* Removes default padding */
  list-style: none;
    /* Removes bullet points */
    margin-top: 20px;
}

.sidebar-item {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px 20px;
  text-decoration: none;
  color: #555;
  margin-left: -20px;
}

.sidebar-text {
  font-size: 1.2rem;
  /* Matches the sidebar text size */
}

/* Sidebar logout section */
.sidebar-logout {
  border-top: 2px solid #f1f1f1; /* Light gray border */
}

.sidebar-logout .logout-link {
  margin-top: 16px;
  font-size: 1.2rem;
  color: rgb(236, 0, 0);
  text-decoration: none;
  background: none;
  border: none;
  padding: 0px;
  width: 100%; /* Full width for button */
  display: block; /* Block-level for easy tapping */
  font-weight: 600;
}

.logout-icon {
  margin-right: 10px; /* Add spacing between the icon and text */
  font-size: 1rem;
  color: rgb(236, 0, 0);
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 10px;
  right: -200px; /* Initially hidden off-screen */
  width: 170px; /* Adjust width as needed */
  height: calc(100% - 50px); /* Full height */
  background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(9px);
  transition: right 0.2s ease; /* Smooth slide-in transition */
  z-index: 300; /* Above the overlay */
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  border-left: 1px solid #ddd;
      padding-top: 30px;
}

.sidebar.open {
  right: 0; /* Slide in from the right */
}

/* Sidebar Overlay */
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire viewport */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.7); /* Black with 10% opacity */
  backdrop-filter: blur(7px);
  z-index: 200; /* Below the sidebar and header */
  transition: opacity 0.3s ease; /* Smooth fade-in/fade-out effect */
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Disable interaction when hidden */
}

.sidebar-overlay.sidebar-open {
  opacity: 1; /* Fully visible when sidebar is open */
  pointer-events: auto; /* Enable interaction */
}

/* Login and Signup Buttons */
.nav-login-button {
  background-color: white;
    color: black;
    font-weight: 600;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-left: -20px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.nav-signup-button {
  background-color: #f1f1f1;
  color: black;
  font-weight: 600;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: -20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.transparent-navbar .nav-login-button {
  background-color: transparent;
  color: white;
  font-weight: 600;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: -20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.transparent-navbar .nav-signup-button {
  background-color: white;
  color: black;
  font-weight: 600;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: -20px;
  margin-bottom: 5px;
  margin-top: 5px;
}


/* Hide icons on mobile while keeping Login and Signup buttons visible */
@media (max-width: 768px) {
  .nav-icon-item {
    display: none;
    /* Hide all icons on mobile */
  }

}

/* Bottom Navbar Styling */
.bottom-navbar {
  display: none;
  /* Hidden by default */
}

@media (max-width: 768px) {
  .bottom-navbar {
    display: flex;
    /* Show on mobile screens */
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(9px);
    /* Apply blur effect */
    border-top: 1px solid #ddd;
    padding: 10px 0;
    z-index: 1000;
    padding-bottom: 25px;
    padding-top: 15px;
  }

  .bottom-navbar .nav-icon {
    font-size: 1.6rem;
    color: rgba(0, 0, 0, 0.6);
  }

    .bottom-navbar .nav-icon.active {
      color: black;
      /* Black color for the active button */
    }

}

    .bottom-navbar .nav-profile-picture.active {
      border: 2px solid black;
    }

.post-icon{
    display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-shrink: 0;
      font-size: 1.2rem;
        padding: 5px;
          border: 3px solid white;
          box-shadow: 0 0 0 4px #5865F2;
      color: white;
      border-radius: 50%;
      background: #5865F2;
      background-size: 400% 400%;
      animation: gradientAnimation 15s ease-in-out infinite;
      margin-top: -3px;
      height: 17px;
      width: 17px;
}

.post-icon.active {

}

.sidebar-toggle-icon{
      display: flex;
        align-items: center;
        /* Vertically align icon */
        justify-content: center;
        /* Horizontally center icon */
        cursor: pointer;
        /* Ensure it's clickable */
        flex-shrink: 0;
        /* Prevent shrinking */
        font-size: 1.6rem;
        color: black
}