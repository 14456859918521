.alert-actions {
    display: flex;
    /* Adjust spacing between icons */
    position: absolute;
    /* Place relative to .feed-post-card */
    top: 0px;
    /* Distance from the top of the card */
    right: 0px;
    /* Distance from the right of the card */
    z-index: 10;
    /* Ensure it appears above other content */
        margin: 20px;
        gap: 10px;
}

.pin-alert-button,
.delete-alert-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s;
}

.delete-alert-button {
    background-color: #555;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
}

.pin-alert-button{
        color: white;
            padding: 5px 10px;
            border-radius: 50%;
}

