   .feed-post-card{
   border: 2px solid #f1f1f1;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
    position: relative;
    margin-bottom: 10px;
    transition: transform 0.2s ease,
    border 0.2s ease;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
   }

/* Odds Section */
.feed-post-odds {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    margin-top: -30px;
}

.feed-post-top {
    display: flex;
    justify-content: space-between;
    /* Ensures date and odds stay on the same line */
    align-items: center;
    /* Aligns text vertically */
    gap: 8px;
    /* Adds spacing between date and odds */
}

.swipeable-wrapper {
    width: 100%;
    height: 100%;
    touch-action: pan-y;
    /* Prevent conflicts with vertical scrolling */
    position: relative;
    /* Ensure it aligns with the card */
    z-index: 1;
    /* Ensure swipeable area is above other elements */
}

/* .play-border {
    border-radius: 4px;
    border: 4px solid #5865F2 !important;
    background-clip: padding-box;
    box-shadow:
        0 0 2px rgba(0, 170, 255, 0.6),
        0 0 4px rgba(0, 170, 255, 0.4),
        0 0 6px rgba(0, 170, 255, 0.2) !important;
    transition: border-color 0.4s ease, box-shadow 0.6s ease;
} */

.play-border {
    color: #5865F2 !important;
    transition: background-color 0.4s ease, color 0.4s ease;
}


.feed-post-card:hover {
    transform: scale(1.01);
}

.feed-post-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.feed-profile-picture {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.feed-post-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feed-post-kapper {
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.feed-post-date {
    font-size: 1rem;
    color: #555;
    font-weight: 600;
}



/* Units and League Section */
.feed-post-units-league-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 5px;
}

.feed-post-league,
.feed-post-units {
    font-size: .9rem;
    background-color: #f1f1f1;
    color: black;
    padding: 6px 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-weight: 600;
}

/* Content Section */
.feed-post-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.feed-post-analysis{
    font-size: 1rem;
        word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: normal;
            color: #555;
            border-radius: 2px;

}
.feed-bet-legs li {
        font-size: 1.2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    margin-bottom: 0px;
}

.feed-post-image {
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    padding: 0px;
}
