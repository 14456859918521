.recent-win-feed-loading,
.recent-win-feed-error,
.recent-win-feed-empty {
    text-align: center;
    margin: 20px 0;
    color: #777;
}

.recent-win-feed {
    display: flex;
    flex-direction: column;
}

.show-all-button {
    margin: 20px auto;
    display: block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #555;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
