/* General Container Styling */
.about-me-container {
    margin: 20px auto;
    margin-top: 5px;
    padding: 15px;
    background-color: white;
    border: 2px solid #f1f1f1;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: transform 0.2s ease;
}

.about-me-container:hover {
    transform: scale(1.01);
    /* Slightly increase the size */
}

/* Buttons Container - Top Right */
.about-buttons {
    position: absolute;
    top: 10px;
    right: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
}

/* Title & Body Fields */
.about-title {
    /* Added more space above the title */
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
}
.about {
    font-size: 1.1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    margin-bottom: 10px;
    margin-top: -15px;
    color: #555;
    /* Ensures readability */
}

/* Input Container - More Space */
.about-form {
    margin-top: 50px;
    /* Added space above the input container */
}

/* Input Fields */
.about-me-container #title,
.about-me-container #body {
    color: black;
    font-weight: normal;
    padding: 5px;
    border-radius: 4px;
    width: 100%;
    border: 2px solid #f1f1f1;
    margin-top: 5px;
    /* More space above input fields */
}

.about-me-container #title{
font-size: 1.5rem;
    font-weight: 600;
    color: black;
}

.about-me-container #body {
    font-size: 1.1rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        margin-bottom: 0px;
        color: #555;
        /* Ensures readability */
}

/* Edit Icon */
.edit-icon-about {
    margin-top: 5px;
    cursor: pointer;
    font-size: 1rem !important;
    padding: 12px;
    border-radius: 50%;
    color: white;
    background: #555;
}

/* Save & Cancel Buttons */
.about-save-button,
.about-cancel-button {
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    padding: 8px 10px;
    margin-top: 5px;
}

.about-save-button {
    background: #5865F2;
    color: white;
}

.about-cancel-button {
    color: black;
    background-color: white;
}